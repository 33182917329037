




































import Vue from "vue";
import moment from "moment";
import { Toast } from "vant";
import { dispatch, CouponActivity, Coupon } from "@/store";
import { share } from "@/utils";

export default Vue.extend({
  data() {
    return {
      loading: false,
      couponActivity: {} as CouponActivity,
      claimedList: [] as { id: string; uuid: string }[],
    };
  },
  computed: {
    AMCodeList(): Coupon[] {
      return (this.couponActivity.coupon_infos || []).filter(
        (coupon: Coupon) => coupon.coupon.category === "early_bird"
      );
    },
    couponList(): Coupon[] {
      return (this.couponActivity.coupon_infos || []).filter(
        (coupon: Coupon) => coupon.coupon.category === "special_offer"
      );
    },
    time(): string {
      return `活动时间 : ${moment(this.couponActivity.start_at).format(
        "YYYY-MM-DD HH:mm:ss"
      )} 至 ${moment(this.couponActivity.end_at).format(
        "YYYY-MM-DD HH:mm:ss"
      )}`;
    },
    timeTip(): string {
      if (moment(this.couponActivity.start_at).isAfter()) {
        return "活动还未开始";
      } else if (moment(this.couponActivity.end_at).isBefore()) {
        return "活动已经结束";
      } else if (!this.couponActivity.is_active) {
        return "活动已经关闭";
      }
      return "";
    },
  },
  methods: {
    getCouponActivity() {
      const { id } = this.$route.params;
      if (id) {
        if (this.loading) return;
        this.loading = true;
        dispatch.couponsGetActivity(id).then((res) => {
          if (res.activity_type === "public") {
            this.loading = false;
            this.couponActivity = res;
            share({
              title: res.title,
              desc: res.description,
              link: window.location.href,
              imgUrl: "https://m.angrymiao.com/logo-medium.png",
            });
          } else {
            this.$router.replace(this.$paths.home);
          }
        });
      }
    },
    onCouponClick(coupon: Coupon) {
      if (this.timeTip) {
        Toast(this.timeTip);
        setTimeout(this.getCouponActivity, 1000);
        return;
      }
      const { id } = this.$route.params;
      const { uuid, title } = coupon.coupon;
      const { per_claim_number } = coupon.coupon_rule;
      dispatch
        .couponsClaim({
          coupon_activity_custom_id: id,
          coupon_uuid: uuid,
        })
        .then((res) => {
          this.claimedList.push({ id: res.id, uuid });
          Toast(`“${title}”已领取成功，快去使用吧！`);
        })
        .catch((res) => {
          Toast.clear();
          switch (res.message) {
            case "out of number":
              Toast(`“${title}” 已被领完了`);
              break;
            case "limit to claim":
              Toast(
                `“${title}” 每人限领${per_claim_number}张，您已领取${per_claim_number}张`
              );
              break;
            case "not allow to create, status error":
              Toast(`活动已关闭`);
              break;
            case "authentication credentials were not provided":
              break;
            default:
              Toast(`领取失败，请重新尝试`);
              break;
          }
          setTimeout(this.getCouponActivity, 1000);
        });
    },
    getClaimed(uuid: string) {
      return !!this.claimedList.find((i) => i.uuid === uuid);
    },
    onClaimedClick(coupon: Coupon) {
      const {
        validity_goods_type,
        validity_goods_type_ids,
        start_at,
      } = coupon.coupon_rule;
      const claimed = this.claimedList.find(
        (i) => i.uuid === coupon.coupon.uuid
      );
      if (moment().isBefore(start_at)) {
        Toast(`此券还未到使用时间`);
        return;
      }
      if (validity_goods_type === "all") {
        this.$router.push(this.$paths.home);
      } else if (validity_goods_type_ids && validity_goods_type_ids.length) {
        const query: any = { ids: validity_goods_type_ids.join() };
        if (coupon.coupon.category === "early_bird") {
          query.user_coupon_id_am_code = claimed?.id;
        }
        this.$router.push({
          path: this.$paths.products,
          query,
        });
      } else {
        this.$router.push(this.$paths.home);
      }
    },
  },
  created() {
    this.getCouponActivity();
  },
  beforeDestroy() {
    share({
      title: "Angry Miao",
      desc: "在 Angry Miao 发掘更多新奇",
      link: window.location.href,
      imgUrl: "https://m.angrymiao.com/logo-medium.png",
    });
  },
});
